.slider {
  display: flex;
  position: relative;
  background: transparent;

  &__container {
    display: flex;
    padding: 0 20px;
    transition: transform 300ms ease 100ms;
    z-index: 3;
    width: 100%;
  }

  &:not(&--open) .item:hover .show-details-button {
    opacity: 1;
  }

  &:not(&--open) .item:hover {
    transform: scale(1.5) !important;
  }

  &:not(&--open):hover .item {
    transform: translateX(-25%);
  }

  &:not(&--open) .item:hover ~ .item {
    transform: translateX(25%);
  }
}

.wrapper-absolute {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: transparent;
}
