.VideoList-container {
  margin: 8px 0px;
  display: flex;
  cursor: pointer;
  border: none;
  outline: none;
  -webkit-appearance: none;
  width: 100%;
  position: relative;
  padding: 0;
  background: #f8fafc;
  max-width: 800px;
  flex-wrap: wrap;
  &::after {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    content: "";
    transition: opacity 0.2s ease-out;
    box-shadow: 0px 6px 4px -4px rgba(0, 0, 0, 0.1), 0px 14px 37px rgba(0, 0, 0, 0.2);
  }
  &:hover {
    &::after {
      opacity: 1;
    }
  }
  .VideoList-video {
    width: 50%;
    max-width: 450px;
    flex-shrink: 0;
    @media (max-width: 550px) {
      width: 100%;
      max-width: 100%;
    }
  }
  .VideoList-info {
    flex: 1;
    text-align: left;
  }
}
